import React, { useEffect, useMemo, useState } from "react";
import { DataGridPro, DataGridProProps, gridClasses } from "@mui/x-data-grid-pro";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useIsScrolled } from "libs/hooks/useIsScrolled";
import { theme } from "ui/theme";
import { useHasScrollbar } from "libs/hooks";
import { waitForElementToExist } from "libs/dom";
import { cellHorizontalPadding, gridColors, gridSpacing } from "../theme";
import { customGridClasses } from "../constants";
import { EMPTY_GRID_ACTIONS } from "../actionsColumnConfig";

const zIndex = {
  menu: 1000,
  resizer: 10001,
};

const gridPanelStyles = (): SxProps<Theme> => ({
  [`& .${gridClasses.filterFormOperatorInput}, & .${gridClasses.filterFormColumnInput}`]: {
    justifyContent: "flex-end",
  },
  [`& .${gridClasses.filterFormOperatorInput}`]: {
    width: "140px",
  },
});

const gridFilterPanelStyles = (): SxProps<Theme> => ({
  [`& .${gridClasses.panelFooter}`]: { display: "none" },
  [`& .${gridClasses.filterFormDeleteIcon}`]: { marginBottom: "6px" },
});

interface GridDisplayState {
  scroll: {
    isScrolled: boolean;
    vertical: boolean;
    horizontal: boolean;
  };
  pinnedColumns: {
    hasLeft: boolean;
    hasRight: boolean;
  };
  hasActions?: boolean;
  alignTop?: boolean;
  isExpandable?: boolean;
}

export const gridStyles = ({
  scroll,
  pinnedColumns,
  hasActions,
  alignTop,
  isExpandable,
}: GridDisplayState): SxProps<Theme> => ({
  border: "none",
  color: gridColors.main,
  [`& .${gridClasses.main}`]: {
    borderTopWidth: "2px",
    borderTopColor: theme.palette.action.disabledBackground,
  },
  [`& .${gridClasses.cell}`]: {
    "position": "relative",
    "backgroundColor": "#fff",
    "border": "none",
    "padding": alignTop ? `16px ${cellHorizontalPadding}` : `0px ${cellHorizontalPadding}`,
    "transition": ".3s ease-out background-color",
    "cursor": "pointer",
    [`&:not([data-field="actions"])`]: {
      ...(alignTop
        ? {
            alignItems: "start",
            lineHeight: "1.8",
          }
        : {}),
    },
    "&:focus, &:focus-within": {
      outline: "none",
    },
    [`&.${customGridClasses.zeroNumberCell}`]: {
      color: gridColors.darkGrey,
    },
    [`&.${customGridClasses.highlightCell}`]: {
      color: gridColors.highlight,
      fontWeight: 500,
    },
    [`&[data-field="__detail_panel_toggle__"]`]: {
      paddingLeft: "0px",
    },
    [`&[data-field="__check__"]`]: {
      paddingLeft: isExpandable ? "0px" : gridSpacing,
    },
    [`&:not([data-field="__detail_panel_toggle__"]):not([data-field="__check__"])`]: {
      paddingLeft: isExpandable ? cellHorizontalPadding : "32px",
    },
  },
  [`& .${gridClasses.pinnedColumns}`]: {
    minHeight: "0 !important",
    background: "transparent",
    overflow: "visible",
    display: "flex",
    flexDirection: "column",
    [`&.${gridClasses["pinnedColumns--right"]}`]: {
      zIndex: pinnedColumns.hasRight || hasActions ? 1 : -1,
    },
  },
  [`& .${gridClasses.columnHeaders}`]: {
    borderBottomWidth: "2px",
    borderBottomColor: theme.palette.action.disabledBackground,
    zIndex: 2,
    boxShadow: !scroll.isScrolled && theme.shadows[0],
    background: "#fff",

    // Override native style which hides last column border
    [`& .${gridClasses.columnHeadersInner} .${gridClasses.columnHeader}:last-child`]: {
      borderRight: `2px solid ${theme.palette.action.disabledBackground}`,
    },

    [`& .${gridClasses.columnHeadersInner} .${gridClasses.columnHeader}:first-child`]: {
      marginLeft: isExpandable ? "0px" : gridSpacing,
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    background: "#fff",
    borderRight: `2px solid ${theme.palette.action.disabledBackground}`,
    outline: "none !important",
    padding: 0,
    [`.filter-list-icon-button > svg`]: {
      display: "none",
    },
    [":hover"]: {
      [`.filter-list-icon-button > svg`]: {
        display: "block",
      },
    },
    [`&:not(.${gridClasses.columnHeaderCheckbox}, .${customGridClasses.actionsHeader}) .${gridClasses.columnHeaderTitleContainerContent}`]:
      {
        paddingTop: "8px",
      },
    [`& .${gridClasses.menuIcon}`]: {
      margin: 0,
      button: {
        zIndex: zIndex.menu,
      },
    },
    [`&.${gridClasses.columnHeaderCheckbox} > .${gridClasses.columnSeparator}`]: {
      display: "none !important",
    },
    [`&[data-field="__detail_panel_toggle__"]`]: {
      border: "none",
      [`.${gridClasses.columnSeparator}`]: {
        display: "none",
      },
    },
    [`&:not(.${gridClasses.columnHeaderCheckbox}) .${gridClasses.iconButtonContainer} > button`]: {
      padding: `${gridSpacing} 3px`,
    },
    [`&.${gridClasses.columnHeaderCheckbox}, & .${gridClasses.cellCheckbox}`]: {
      borderRight: "none",
    },
  },
  [`& .${gridClasses["pinnedColumnHeaders--right"]} .${gridClasses.columnHeader}`]: {
    [`&:last-child`]: {
      borderRight: "none",
    },
  },
  [`& .${gridClasses.columnHeaderTitleContainerContent}`]: {
    padding: `6px ${cellHorizontalPadding}`,
  },
  [`& .${gridClasses["columnHeader--sortable"]}:hover .${gridClasses.columnHeaderTitleContainerContent},
    & .${gridClasses["columnHeader--sorted"]} .${gridClasses.columnHeaderTitleContainerContent}`]: {
    paddingRight: 0,
  },
  [`& .${gridClasses["columnHeader--sortable"]}.${gridClasses["columnHeader--alignRight"]}:hover .${gridClasses.columnHeaderTitleContainerContent},
    & .${gridClasses["columnHeader--sorted"]}.${gridClasses["columnHeader--alignRight"]} .${gridClasses.columnHeaderTitleContainerContent}`]:
    {
      paddingRight: gridSpacing,
      paddingLeft: 0,
    },
  [`& .${gridClasses["columnSeparator--sideRight"]}`]: {
    width: "6px",
    right: "-15px",
    zIndex: zIndex.resizer,
  },
  [`& .${gridClasses.checkboxInput} svg`]: {
    fontSize: 18,
  },
  [`& .${gridClasses.checkboxInput}`]: {
    padding: gridSpacing,
  },
  [`& .${gridClasses.toolbarContainer}`]: {
    padding: 0,
    marginBottom: "21px",
    height: "auto",
  },
  [`& .${gridClasses.columnHeaderTitleContainer}`]: {
    paddingRight: "4px",
  },
  [`& .${gridClasses.columnHeaderCheckbox} .${gridClasses.columnHeaderTitleContainerContent},
    & .${gridClasses.cellCheckbox},
    & .${customGridClasses.actionsHeader} .${gridClasses.columnHeaderTitleContainerContent}`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`& .${gridClasses.detailPanelToggleCell}`]: {
    padding: "0px",
  },
  [`& .${gridClasses.checkboxInput}`]: {
    "padding": "0px",
    "& svg": {
      fontSize: "24px",
    },
  },
  [`& .${gridClasses.cellContent}`]: {
    fontSize: "13px",
  },
  [`& .${gridClasses.virtualScroller}`]: {
    "&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-track:hover": {
      background: "transparent",
    },
  },
  [`& .${gridClasses.virtualScrollerRenderZone} .${gridClasses.row}`]: {
    [`&.picked`]: {
      boxShadow: "0 1px 0 0 #0F62FE, 0 -1px 0 0 #0F62FE",
      [`& .${gridClasses.cell}:first-of-type`]: {
        boxShadow: "inset 1px 0px 0px 0px #0F62FE",
      },
      [`& .${gridClasses.cell}:last-of-type`]: {
        boxShadow: "inset -1px 0px 0px 0px #0F62FE",
      },
    },
    [`&.Mui-hovered, &.Mui-hovered .${gridClasses.cell}, &.picked .${gridClasses.cell}`]: {
      backgroundColor: "#F3F7FF !important",
    },
  },
  [`& .${gridClasses.virtualScrollerContent} .${gridClasses.pinnedColumns}`]: {
    [`& .${gridClasses.row}`]: {
      boxShadow: "0px 4px 0 0 #fff, 0px -4px 0 0 #fff",
      [`&.${gridClasses["row--lastVisible"]}`]: {
        boxShadow: "0px 1px 0 0 #fff, 0px -4px 0 0 #fff",
      },
    },
  },
  // Actions column is pinned, but we show it as regular column unless we have horizontal scroll or other pinned columns
  ...(!pinnedColumns.hasRight && (!scroll.horizontal || !hasActions)
    ? {
        [`& .${gridClasses.virtualScrollerContent} .${gridClasses["pinnedColumns--right"]}`]: {
          boxShadow: "none",
          [`& .${gridClasses.row}`]: {
            boxShadow: "none",
            [`&.${gridClasses["row--lastVisible"]}`]: {
              boxShadow: "none",
            },
            [`&.picked`]: {
              boxShadow: "0 1px 0 0 #0F62FE, 0 -1px 0 0 #0F62FE !important",
              [`& .${gridClasses.cell}:last-of-type`]: {
                boxShadow: "inset -1px 0px 0px 0px #0F62FE",
              },
            },
            [`&.Mui-hovered, &.Mui-hovered .${gridClasses.cell}, &.picked .${gridClasses.cell}`]: {
              backgroundColor: "#F3F7FF !important",
            },
          },
        },
        [`& .${gridClasses.pinnedColumnHeaders}.${gridClasses["pinnedColumnHeaders--right"]}`]: {
          boxShadow: "none",
          borderLeft: "2px solid rgba(0, 0, 0, 0.12)",
        },
        [`& .${gridClasses.virtualScrollerRenderZone} .${gridClasses.cell}:last-of-type`]: {
          boxShadow: "none !important",
        },
      }
    : {}),
});

export function StyledDataGridPro({
  alignTop,
  customSx = (style) => style,
  isExpandable,
  ...props
}: DataGridProProps & {
  alignTop?: boolean;
  isExpandable?: boolean;
  customSx?: (style: SxProps<Theme>) => SxProps<Theme>;
}) {
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>();
  const [virtualScrollerElement, setVirtualScrollerElement] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (gridElement && !virtualScrollerElement) {
      const waitForVirtualScroller = async () => {
        const el = await waitForElementToExist(gridElement, `.${gridClasses.virtualScroller}`);
        if (el) {
          setVirtualScrollerElement(el as HTMLDivElement);
        }
      };

      waitForVirtualScroller();
    }
  }, [gridElement, virtualScrollerElement]);

  const hasActions = useMemo(
    () =>
      props.columns.some(
        (column) => column.type === "actions" && (column as any).getActions != EMPTY_GRID_ACTIONS
      ),
    [props.columns]
  );
  const isScrolled = useIsScrolled(virtualScrollerElement);
  const scrollbarState = useHasScrollbar(virtualScrollerElement);
  const pinnedColumns = useMemo(
    () => ({
      hasRight: !!props.pinnedColumns?.right?.filter(
        (col) => props.columnVisibilityModel?.[col] && col !== "actions"
      )?.length,
      hasLeft: !!props.pinnedColumns?.left?.filter((col) => props.columnVisibilityModel?.[col])
        ?.length,
    }),
    [props.pinnedColumns, props.columnVisibilityModel]
  );

  const styles = gridStyles({
    scroll: {
      isScrolled,
      ...scrollbarState,
    },
    hasActions,
    pinnedColumns,
    alignTop,
    isExpandable,
  });

  return (
    <DataGridPro
      ref={setGridElement}
      sx={customSx(styles)}
      {...props}
      slotProps={{
        ...props.slotProps,
        panel: { ...props.slotProps?.panel, sx: gridPanelStyles() },
        filterPanel: { ...props.slotProps?.filterPanel, sx: gridFilterPanelStyles() },
        loadingOverlay: {
          ...props.slotProps?.loadingOverlay,
          style: !props.rows.length
            ? {
                background: "transparent",
              }
            : {},
        },
      }}
    />
  );
}
