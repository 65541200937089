import { isFinite } from "lodash";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { GridFilters } from "modules/grid/filterTypes";

enum Operator {
  Contains = "contains",
  Equals = "equals",
  StartsWith = "startsWith",
  EndsWith = "endsWith",
  IsNotEmpty = "isNotEmpty",
  After = "after",
  OnOrAfter = "onOrAfter",
  Before = "before",
  OnOrBefore = "onOrBefore",
  Is = "is",
  IsNot = "isNot",
}
type Handlers = Record<string, Partial<Record<Operator, (rowValue: any, value: any) => boolean>>>;

const handlers: Handlers = {
  string: {
    contains: (rowValue: string, value: string) =>
      rowValue?.toLowerCase?.()?.includes(value?.toLowerCase?.()),
    equals: (rowValue: string, value: string) =>
      rowValue?.toLowerCase?.() === value?.toLowerCase?.(),
    startsWith: (rowValue: string, value: string) =>
      rowValue?.toLowerCase?.()?.startsWith(value?.toLowerCase?.()),
    endsWith: (rowValue: string, value: string) =>
      rowValue?.toLowerCase?.()?.endsWith(value?.toLowerCase?.()),
    isNotEmpty: (rowValue: string) => {
      return !!rowValue;
    },
  },
  dateTime: {
    after: (rowValue: string, value: string) => new Date(rowValue) > new Date(value),
    onOrAfter: (rowValue: string, value: string) => new Date(rowValue) >= new Date(value),
    before: (rowValue: string, value: string) => new Date(rowValue) < new Date(value),
    onOrBefore: (rowValue: string, value: string) => new Date(rowValue) <= new Date(value),
    is: (rowValue: string, value: string) => new Date(rowValue) === new Date(value),
    isNot: (rowValue: string, value: string) => new Date(rowValue) !== new Date(value),
    isNotEmpty: (rowValue: string) => {
      return !!rowValue;
    },
  },
};

export function applyFilters(data: any[], filters: GridFilters) {
  if (!filters || !Object.keys(filters).length) {
    return data;
  }

  return data.filter((row) => {
    return Object.entries(filters).every(([field, { items, type }]) => {
      for (const { itemType, value, operator } of items) {
        if (itemType === "filter") {
          return handlers[type]?.[operator as Operator]?.(
            String(row[field] || ""),
            (Array?.isArray(value) ? value[0] : value) as string
          );
        }

        return true;
      }

      return true;
    });
  });
}

const sortComparators = {
  number: (a: any, b: any) => parseFloat(a) - parseFloat(b),
  string: (a: any, b: any) => String(a || "").localeCompare(String(b || "")),
  array: (a: any, b: any) => a.length - b.length,
  date: (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime(),
};

function getFieldsType(a: any, b: any) {
  if (Array.isArray(a) && Array.isArray(b)) {
    return "array";
  }
  if (!isNaN(new Date(a).getTime()) && !isNaN(new Date(b).getTime())) {
    return "date";
  }
  if (isFinite(parseFloat(a)) && isFinite(parseFloat(b))) {
    return "number";
  }
  return "string";
}
export function applySort(data: any[], sortModel: GridSortModel) {
  if (!sortModel || !sortModel.length) {
    return data;
  }

  return [...data].sort((a, b) => {
    for (const { sort, field } of sortModel) {
      if (a[field] !== b[field]) {
        const type = getFieldsType(a[field], b[field]);
        return (sort === "asc" ? 1 : -1) * sortComparators[type](a[field], b[field]);
      }
    }

    return 0;
  });
}

export function applySearch<T = any>(data: T[], field: keyof T, search: string | undefined) {
  return search
    ? data.filter((item) => {
        const value = item[field];
        if (typeof value !== "string") {
          throw new Error("Search is only supported for string fields");
        }
        return value.toLowerCase().includes(search.toLowerCase());
      })
    : data;
}
