import React from "react";
import axios from "axios";
import { Root, createRoot } from "react-dom/client";
import { ReactComponent as CHLogo4 } from "assets/icons/ch_icon4.svg";
import { ENV } from "runenv";
import { resolveService } from "service";
import { logInfo } from "shared/logger";
import { preloadFrontendConfig } from "shared/auth-hooks";
import { isRbacError } from "shared/check-error";
import { QueryClient } from "@tanstack/react-query";
import { fetchMe, isAuthenticated } from "modules/auth/effects";
import { preloadBetas } from "modules/preferences/state";
import { preloadTermsState } from "modules/terms/state";
import { notification } from "modules/notification";
import { install } from "api/auth/interceptors";
import { Box } from "ui/atoms/Box";
import { getToken } from "api/auth/storage";
import { preloadCategoryListAll } from "modules/risks/dashboard/apiHooks";
import { App } from "./App";
import {
  handlePendoIdentityUpdate,
  initExternalServices,
  watchUserChanges,
} from "./setupExternalServices";
import { store } from "./setupStore";
import { preloadTimezoneSettings } from "../modules/timezone/timezoneApiHooks";
import { preloadCurrentUser } from "../modules/users/apiHooks";

let root: Root;
function getRoot() {
  if (!root) {
    root = createRoot(document.getElementById("root")!);
  }

  return root;
}

function mount(cmp: React.ReactElement) {
  const root = getRoot();
  root.render(cmp);
}
export async function main() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry(failureCount: number, error: unknown) {
          // Do not retry on RBAC errors
          if (isRbacError(error).result) {
            return false;
          }

          return failureCount < 3 ? true : false;
        },
      },
      mutations: {
        onError(error) {
          console.error("error", error);
          if (isRbacError(error).result) {
            notification.error({
              message: "Access denied",
            });
          } else {
            reportError(error as object);
          }
        },
      },
    },
  });

  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
  }
  install(axios);
  mount(
    <Box sx={{ centerContent: true }}>
      <CHLogo4 />
    </Box>
  );
  let preloadedConfig = { CUSTOMER_NAME: "" };
  if (isAuthenticated()) {
    try {
      preloadedConfig = (await preloadFrontendConfig(queryClient)) as typeof preloadedConfig;
    } catch (e) {
      console.error("Failed to preload frontend config", e);
    }
  }
  if (process.env.NODE_ENV !== "test") {
    await initExternalServices(queryClient);
    watchUserChanges(store, preloadedConfig.CUSTOMER_NAME);
  }

  if (isAuthenticated()) {
    try {
      const token = await getToken();
      const user = await fetchMe(token);

      await Promise.all([
        preloadBetas(queryClient),
        preloadCurrentUser(queryClient, user.id),
        preloadTimezoneSettings(queryClient, user.id),
        preloadTermsState(queryClient),
        preloadCategoryListAll(queryClient),
      ]);
      handlePendoIdentityUpdate(user, preloadedConfig.CUSTOMER_NAME);
    } catch (e: any) {
      if (e.isAxiosError && e.response.status === 403) {
        // If token is invalid we should remove it from cookies, so it won't be used for api requests
        const authService = await resolveService("authService");
        authService.removeAuthCookie();
        authService.logout();
      }
      console.error("user is not authenticated");
    }
  } else {
    // preload auth service since it's required for every action on login page
    resolveService("authService")
      .then(() => {})
      .catch((err) => console.error("authService was not resolved", err));
  }
  logInfo("rendering app");
  if (localStorage.getItem("debug")) {
    logInfo(ENV);
  }
  mount(<App store={store} queryClient={queryClient} />);
}
