import { z } from "zod";
import { types_OperatorEnum, types_SelectorEnum, types_SizeUnitEnum } from "api/gen";
import { types } from "api";

export const sizeEnumOrdered = [
  types_SizeUnitEnum.SizeUnitByte,
  types_SizeUnitEnum.SizeUnitKilobyte,
  types_SizeUnitEnum.SizeUnitMegabyte,
  types_SizeUnitEnum.SizeUnitGigabyte,
  types_SizeUnitEnum.SizeUnitTerabyte,
] as const;

const SizeByte = 1;
const SizeKB = 1024;
const SizeMB = SizeKB * SizeKB;
const SizeGB = SizeKB * SizeMB;
const SizeTB = SizeKB * SizeGB;
const sizeEnumValues = [SizeByte, SizeKB, SizeMB, SizeGB, SizeTB];

export function toNumber(unit: number, suffix: Exclude<types.SizeUnit, "">) {
  const index = sizeEnumOrdered.indexOf(suffix);
  return unit * sizeEnumValues[index === -1 ? 0 : index];
}

function validateRange(
  first: { unit?: any; value?: any },
  second: { unit?: string; value?: string }
) {
  if (!first?.value && !second?.value) {
    return "Either lower or upper bound should be specified";
  }
  const firstValue = toNumber(parseInt(first?.value) || 0, first?.unit);
  const secondValue = toNumber(parseInt(second?.value!) || Infinity, second?.unit as any);

  const res = secondValue - firstValue;
  if (res < 0) {
    return `"From" should be smaller`;
  }
  return;
}

export const LIST_ERROR_MESSAGE = "Select at least one list";

export function noErrorsOrEmptyList(query?: Record<string, any>) {
  if (!query) {
    return true;
  }
  const rules = query.rules || [];
  if (rules.length === 0) {
    return true;
  }
  if (rules.length > 1) {
    return false;
  }
  const conditions = rules[0]?.conditions || [];
  return conditions.length === 1 && conditions[0].field_name === LIST_ERROR_MESSAGE;
}

const conditionSchema = z
  .object({
    field_name: z.string(),
    operator: z.nativeEnum(types_OperatorEnum),
    negated: z.boolean().default(false),
    selector: z.nativeEnum(types_SelectorEnum),
    values: z
      .array(
        z.object({
          value: z.string().optional(),
          min_count: z
            .number()
            .transform((v: any) => parseInt(v, 10))
            .optional(),
          unit: z.string().optional(),
          list_id: z.string().optional(),
        })
      )
      .default([]),
    case_sensitive: z.boolean().default(false),
  })
  .superRefine((v, ctx) => {
    const listMatcherField = v.operator.startsWith("list_");
    if (listMatcherField) {
      if (!v.values[0]?.list_id) {
        ctx.addIssue({
          code: "custom",
          path: ["field_name"],
          message: LIST_ERROR_MESSAGE,
        });
      }
    }
    if (v.operator === types_OperatorEnum.OperatorIsBetween) {
      const error = validateRange(v.values[0], v.values[1]);
      if (error) {
        ctx.addIssue({
          code: "custom",
          path: ["values"],
          message: error,
        });
      }
    }
    if (
      v.operator === types_OperatorEnum.OperatorIsGreaterThan ||
      v.operator === types_OperatorEnum.OperatorIsLessThan ||
      v.operator === types_OperatorEnum.OperatorIsEqualTo
    ) {
      if (!v.values[0]?.value) {
        ctx.addIssue({
          code: "custom",
          path: ["values"],
          message: `Value is required`,
        });
      }
    }
  });

export const rulesSchema = z
  .array(
    z.object({
      conditions: z.array(conditionSchema).default([]),
    })
  )
  .default([])
  .nullish();
