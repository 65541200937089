import { useEffect, useMemo } from "react";
import { useCurrentUser, useCurrentUserId, preloadCurrentUser } from "shared/auth-hooks";
import { difference, groupBy, mapValues } from "lodash";
import { DeepPartial } from "shared";
import { useCan } from "shared/permissions";
import { ENV } from "runenv";
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
  keepPreviousData,
} from "@tanstack/react-query";
import { ROLE_ADMIN } from "modules/users/molecules/changeRolesConstants";
import { api, types } from "api";
import { authState, logout, refreshToken } from "modules/auth";
import { IUser } from "api/auth/interfaces";
import { notification } from "modules/notification";
import { logEvent, eventsConstants } from "modules/analytics/Analytics";
import {
  GetRolesDocument,
  ListPermissionsForRoleDocument,
  AssingPermissionsDocument,
  ListRolesForUserDocument,
  AssingRolesDocument,
  CreateRoleDocument,
  GetAllPermissionsDocument,
  UpdateRoleDocument,
  DeleteRoleDocument,
  Role,
  GetScopesDocument,
  DeleteScopeDocument,
  GetUserScopeDocument,
  AssignScopeDocument,
  UpdateScopeDocument,
  CreateScopeDocument,
  ListScopeBindingsDocument,
  ListRoleBindingsDocument,
  ResetPasswordRequest,
} from "api/graphql";
import { sendGQLRequest } from "api/graphql/network";
import { resourceDescriptions } from "api/resources";
import { ScopeUseListKey } from "api/apiHooks";
import { useGrid } from "modules/grid";
import { applyFilters, applySearch, applySort } from "modules/grid/clientSortingAndFiltering";
import { apiauth } from "api/gen";
import { messages } from "./messages";
import { getApiKeyStatus } from "./utils";
import { ApiKey, UserKind } from "./types";

const UsersKey = "users";
const UserIDKey = "user";
const ScopesKey = "scopes";
const ScopeBindingsKey = "scope-bindings";
const RoleBindingsKey = "role-bindings";
const ApiKeysKey = "api-keys";

const usersApi = api.auth.AuthService;

export { useCurrentUser, useCurrentUserId, preloadCurrentUser };

export function useUser({ id }: any) {
  if (!id) {
    return {} as any;
  }

  return useQuery({
    queryKey: [UserIDKey, id],
    queryFn: () => usersApi.UserGet({ id }),
  });
}

export function useUsers() {
  return useQuery({
    queryKey: [UsersKey],
    queryFn: () => usersApi.UsersList({}),
  });
}

export function useDeleteUser() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async (user: IUser) => {
      await usersApi.UserDelete({ id: String(user.id) });
      return user;
    },
    async onSuccess(user: any) {
      cache.invalidateQueries({
        queryKey: [UsersKey],
      });
      const { user: currentUser } = authState.getState();
      if (user.id === currentUser.id) {
        await logout();
      }
      logEvent(eventsConstants.preferences.users.delete);

      notification.open({
        message: messages.notifications.getNotificationText(
          messages.notifications.statuses.removed
        ),
      });
    },
    onError(error: any) {
      notification.error({ message: error.message });
    },
  });
}

export function usePatchUser() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, user }: { id: any; user: Partial<types.User> }) => {
      const { user: currentUser } = authState.getState();

      await usersApi.UserPatch({
        id: id,
        ...(user as any),
      });
      if (id === currentUser.id) {
        await refreshToken();
      }
      logEvent(eventsConstants.preferences.users.update);
      return user;
    },
    onSuccess(_, req) {
      cache.invalidateQueries({
        queryKey: [UsersKey],
      });
      cache.invalidateQueries({
        queryKey: [UserIDKey, req.id],
        refetchType: "active",
      });
      notification.open({
        message: messages.notifications.getNotificationText(
          messages.notifications.statuses.updated
        ),
      });
    },
    onError(error) {
      notification.error({ message: (error as any)?.message });
    },
  });
}

export function useInviteUser() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({ email, roles }: { email: string; roles: types.UserRole[] }) => {
      const response = await usersApi.UserInvite({ email, roles: roles as any });
      logEvent(eventsConstants.preferences.users.inviteUser);
      return response;
    },
    onSuccess() {
      cache.invalidateQueries({
        queryKey: [UsersKey],
      });
    },
    onError(error) {
      notification.error({ message: (error as any)?.message });
    },
  });
}

export function useForgotPassword(props = {} as any) {
  const { data: user } = useCurrentUser();
  return useMutation({
    mutationFn: async ({ id }: ResetPasswordRequest) => {
      return api.auth.AuthService.UserPasswordReset({ id });
    },
    onSuccess(_, { id }: ResetPasswordRequest) {
      notification.success({
        message: messages.resetAuthMethod,
      });
      if (user?.id === id) {
        logout();
      }
    },
    ...props,
  });
}

export function useAuthProviderReset(props = {} as any) {
  const { data: user } = useCurrentUser();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      return api.auth.AuthService.ResetProvider({ id });
    },
    onSuccess(_, { id }: { id: string }) {
      notification.success({
        message: messages.resetAuthProivider,
      });
      if (user?.id === id) {
        logout();
      }
    },
    ...props,
  });
}

export function useResetMFA() {
  const { data: user } = useCurrentUser();
  const cache = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      return api.auth.AuthService.MFAReset({ id });
    },
    onSuccess(_, { id }) {
      notification.success({
        message: messages.resetMFAMethod,
      });
      cache.invalidateQueries({
        queryKey: [UsersKey],
        refetchType: "active",
      });
      if (user?.id === id && id) {
        logout();
      }
    },
  });
}

export function useRolesUpdate() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: (arg: {
      user_id: string;
      roles: string[];
      currentRoles: string[];
      kind?: UserKind;
    }) => {
      return sendGQLRequest(AssingRolesDocument, {
        insert_data: arg.roles.map((el) => ({
          role_id: el,
          subject_id: arg.user_id,
          subject_kind: arg.kind || UserKind.User,
        })),
        user_id: arg.user_id,
        roles_to_delete: difference(arg.currentRoles, arg.roles),
      });
    },
    onSuccess: (_data, arg) => {
      cache.invalidateQueries({
        queryKey: ["roles", arg.user_id],
      });
      cache.invalidateQueries({
        queryKey: [RoleBindingsKey],
      });
    },
  });
}

export function useCreateRole() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({
      role_name,
      description,
      kind,
    }: {
      role_name: string;
      description: string;
      kind: UserKind;
    }) => {
      return sendGQLRequest(CreateRoleDocument, { role_name, description, kind });
    },
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: ["roles"],
      });
    },
  });
}

export function useUpdateRole() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      name,
      description,
    }: {
      id: string;
      name: string;
      description: string;
    }) => {
      return sendGQLRequest(UpdateRoleDocument, { id, name, description });
    },
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: ["roles"],
      });
    },
  });
}

export function useDeleteRole() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      return sendGQLRequest(DeleteRoleDocument, { id });
    },
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: ["roles"],
      });
    },
  });
}

// If user is able to invite we prefetch role and scope list
export function useInviteDataPrefetch() {
  const queryClient = useQueryClient();
  const { data: canInvite } = useCan(
    ENV.FEATURES.CONTENT_BASED_RBAC
      ? ["user__create", "role__read", "scope__read"]
      : ["user__create", "role__read"]
  );
  useEffect(() => {
    if (canInvite) {
      queryClient.prefetchInfiniteQuery({
        queryKey: ["roles"],
        queryFn: () => {
          return sendGQLRequest(GetRolesDocument, { kind: UserKind.User });
        },
        getNextPageParam: () => undefined,
        initialPageParam: undefined,
      });

      queryClient.prefetchInfiniteQuery({
        queryKey: [ScopesKey],
        queryFn: () => sendGQLRequest(GetScopesDocument, {}),
        initialPageParam: undefined,
        getNextPageParam: () => undefined,
      });
    }
  }, [canInvite]);
}

export function useRolesData(kind: UserKind[] = [UserKind.User]) {
  return useInfiniteQuery({
    queryKey: ["roles", kind],
    queryFn: () => {
      return sendGQLRequest(GetRolesDocument, { kind });
    },
    placeholderData: keepPreviousData,
    getNextPageParam: () => undefined,
    initialPageParam: undefined,
  });
}

export function useRolesByUserList(id?: string) {
  if (!id) {
    return {} as any;
  }

  return useQuery({
    queryKey: ["roles", id],
    queryFn: () => sendGQLRequest(ListRolesForUserDocument, { user_id: id }),
  });
}

export const getSortedRoles = (rolesData: Array<DeepPartial<Role>> | undefined) => {
  return (
    rolesData?.reduce((acc: Array<DeepPartial<Role>>, role: DeepPartial<Role>) => {
      if (role.id === ROLE_ADMIN.id) {
        return [role, ...acc];
      }
      acc.push(role);
      return acc;
    }, []) || []
  );
};

export function useRolesList(kind: UserKind[] = [UserKind.User]) {
  const { data, ...rest } = useRolesData(kind);
  const roles = data?.pages[0].role_aggregate.nodes;
  const rolesData = useMemo(() => getSortedRoles(roles), [roles]);

  return { data: rolesData, ...rest };
}

export function usePermissionsRoleList(id: string | null) {
  return useQuery({
    queryKey: ["permissions", id],

    queryFn: () =>
      sendGQLRequest(ListPermissionsForRoleDocument, { role_id: id }).then((data) => {
        return data?.role_permission?.map((item) => {
          const [prefix, mode] = item.permission!.name.split("__");
          const permission = resourceDescriptions[prefix as keyof typeof resourceDescriptions];

          return {
            ...item.permission,
            title: permission.name + " " + mode.toUpperCase(),
            description: permission.description,
          };
        });
      }),

    enabled: !!id,
  });
}

export function usePermissionsList(kind: UserKind) {
  return useInfiniteQuery({
    queryKey: ["permissions", kind],
    queryFn: async () => {
      const response = await sendGQLRequest(GetAllPermissionsDocument, {});
      if (kind === UserKind.ApiKey) {
        const apiKeyPermissions = await api.apiauth.ApiAuthService.GetValidPermissions({});
        response.permission = response.permission.filter((permission) =>
          apiKeyPermissions.permissions!.includes(permission.name)
        );
      }

      return response;
    },
    initialPageParam: undefined,
    getNextPageParam: () => undefined,
    staleTime: 2 * 60 * 1000,
  });
}

export function useRolePermissionsUpdate() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: (arg: { role_id: string; permissions: string[]; currentPermissions: string[] }) => {
      return sendGQLRequest(AssingPermissionsDocument, {
        insert_data: arg.permissions.map((el) => ({ role_id: arg.role_id, permission_id: el })),
        role_id: arg.role_id,
        permissions_to_delete: difference(arg.currentPermissions, arg.permissions),
      });
    },
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: ["roles"],
      });
    },
  });
}

/**
 * Gets roles assigned to each user
 * @returns Record<user_id, Role[]>
 * */
export function useRoleBindings(kind: UserKind = UserKind.User) {
  return useQuery({
    queryKey: [RoleBindingsKey],

    queryFn: async () => {
      const { role_binding } = await sendGQLRequest(ListRoleBindingsDocument, {
        subject_kind: kind,
      });

      return mapValues(groupBy(role_binding || [], "subject_id"), (bindings) =>
        bindings.map((binding) => binding.role)
      );
    },
  });
}

export function useScopesData() {
  return useInfiniteQuery({
    queryKey: [ScopesKey],
    queryFn: () => sendGQLRequest(GetScopesDocument, {}),
    placeholderData: keepPreviousData,
    getNextPageParam: () => undefined,
    initialPageParam: undefined,
  });
}

export function useScopesList({ search }: { search?: string } = {}) {
  const { data, ...rest } = useScopesData();
  const scopes = data?.pages[0].scope_aggregate.nodes;
  const normalizedScopes = useMemo(
    () => (scopes ? applySearch(scopes, "name", search) : undefined),
    [scopes, search]
  );

  return { data: normalizedScopes, ...rest };
}

export function useUserScope(id?: string) {
  return useQuery({
    queryKey: [ScopesKey, id],
    queryFn: () => sendGQLRequest(GetUserScopeDocument, { user_id: id! }),
    enabled: !!id,
  });
}

/**
 * Gets scope assigned to each user
 * @returns Record<user_id, Scope>
 * */
export function useScopeBindings() {
  return useQuery({
    queryKey: [ScopeBindingsKey],

    queryFn: async () => {
      const { scope_binding } = await sendGQLRequest(ListScopeBindingsDocument, {});

      return mapValues(groupBy(scope_binding || [], "subject_id"), (bindings) => bindings[0].scope);
    },
  });
}

export function useCreateScope() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({
      name,
      description,
      created_by_id,
      definition,
    }: {
      name: string;
      description: string;
      created_by_id: string;
      definition: any;
    }) => {
      return sendGQLRequest(CreateScopeDocument, { name, description, created_by_id, definition });
    },
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: [ScopesKey],
      });
      cache.invalidateQueries({
        queryKey: [ScopeUseListKey],
      });
    },
    onError: () => {
      notification.error({
        message: messages.scopes.failedToCreate,
      });
    },
  });
}

export function useUpdateScope() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      name,
      description,
      updated_by_id,
      definition,
    }: {
      id: string;
      name: string;
      description: string;
      updated_by_id: string;
      definition: any;
    }) => {
      return sendGQLRequest(UpdateScopeDocument, {
        id,
        name,
        description,
        updated_by_id,
        definition,
      });
    },
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: [ScopesKey],
      });
      cache.invalidateQueries({
        queryKey: [ScopeUseListKey],
      });
    },
    onError: () => {
      notification.error({
        message: messages.scopes.failedToUpdate,
      });
    },
  });
}

export function useDeleteScope() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => sendGQLRequest(DeleteScopeDocument, { id }),
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: [ScopesKey],
      });
    },
    onError: () => {
      notification.error({
        message: messages.scopes.failedToDelete,
      });
    },
  });
}

export function useAssignScopeToUser() {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: ({ user_id, scope_id }: { user_id: string; scope_id: string }) => {
      return sendGQLRequest(AssignScopeDocument, {
        scope_binding: { scope_id, subject_id: user_id },
      });
    },
    onSuccess: (_data, arg) => {
      cache.invalidateQueries({
        queryKey: [ScopesKey, arg.user_id],
      });
      cache.invalidateQueries({
        queryKey: [ScopeBindingsKey],
      });
    },
    onError: () => {
      notification.error({
        message: messages.changeScope.failedToAssignScope,
      });
    },
  });
}

export function useApiKeysGridItems() {
  const { search, sortModel, filters } = useGrid();
  const query = useQuery({
    queryKey: [ApiKeysKey],
    queryFn: () => api.apiauth.ApiAuthService.ListApiKeys({}),
  });

  const apiKeys = useMemo(() => {
    let result = (query.data?.records as apiauth.ApiKeyDto[]) || [];
    result = result.map((key) => ({ ...key, status: getApiKeyStatus(key) })) as ApiKey[];
    result = applyFilters(result, filters);
    result = applySort(result, sortModel);
    result = applySearch(result, "name", search);
    return result;
  }, [query.data, filters, sortModel, search]);

  return { ...query, data: apiKeys };
}

export function useCreateApiKey(onSuccess: (apiKey: apiauth.ApiKeyDto) => void) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (apiKey: apiauth.CreateApiKeyReq) =>
      api.apiauth.ApiAuthService.CreateApiKey(apiKey),
    onSuccess: (resp) => {
      client.invalidateQueries({
        queryKey: [ApiKeysKey],
      });
      onSuccess(resp.key!);
    },
    onError: () => {
      notification.error({
        message: messages.apiKeys.failedToCreate,
      });
    },
  });
}

export function useUpdateApiKey() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (apiKey: apiauth.ModifyApiKeyReq & { id: string }) =>
      api.apiauth.ApiAuthService.UpdateApiKey(apiKey),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [ApiKeysKey],
      });
    },
    onError: () => {
      notification.error({
        message: messages.apiKeys.failedToUpdate,
      });
    },
  });
}

export function useDeleteApiKey() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => api.apiauth.ApiAuthService.DeleteApiKey({ id }),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [ApiKeysKey],
      });
    },
    onError: () => {
      notification.error({
        message: messages.apiKeys.failedToDelete,
      });
    },
  });
}
